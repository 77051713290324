import React from "react";
import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";

const JobsPage = () => {
  return (
    <Layout>
      <div className="job-page">
        <h1>Travailler chez ByVets</h1>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
          explicabo, obcaecati provident aliquam nemo eligendi, aut, pariatur
          minima voluptatem maxime consequuntur! Provident quos dolores mollitia
          velit sapiente? Id nobis dignissimos natus quibusdam error? Fugiat
          quia ducimus illum expedita modi nemo error deserunt, dolores dolorum.
          Blanditiis quod quasi consectetur reprehenderit dignissimos.
        </p>
        <div className="flex">
          <div></div>
        </div>
        <h2>Nos offres d'emploi</h2>
        <Link to={"/jobs/developpeur-e-front-end"} className="job-link">
          <div className="flex w-full items-center h-24 rounded-lg shadow-lg">
            <div className="w-16 h-full bg-red-500">ah</div>
            <div className="flex-1 pl-2 flex flex-col self-start justify-start h-full">
              <h3 className="flex-1">Développeur.euse front-end (h/f/x)</h3>
              <div className="mb-2">Cdi temps plein</div>
            </div>
            <div className=" p-4 border-l">Voir l'offre</div>
          </div>
        </Link>
      </div>
    </Layout>
  );
};

export default JobsPage;
