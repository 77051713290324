import React, { useEffect, useRef, useState } from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
const TestModalPage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollableDivRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  // Function to handle the scroll
  const handleScroll = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollBy({
        top: 100, // Adjust the scroll amount here
        behavior: "smooth",
      });
    }
  };
  const checkScrollPosition = () => {
    if (scrollableDivRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableDivRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        setIsButtonVisible(false);
      } else {
        setIsButtonVisible(true);
      }
    }
  };

  const checkScrollability = () => {
    if (scrollableDivRef.current) {
      const { scrollHeight, clientHeight } = scrollableDivRef.current;
      setIsButtonVisible(scrollHeight > clientHeight);
    }
  };
  useEffect(() => {
    checkScrollability();

    const div = scrollableDivRef.current;
    if (div) {
      div.addEventListener("scroll", checkScrollPosition);
      return () => {
        div.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, []);

  return (
    <div>
      <h1>IsOpen: {isOpen}</h1>
      <button onClick={() => setIsOpen(true)}>Open</button>
      <button onClick={() => setIsOpen(false)}>Close</button>
      <AlertDialog.Root open={isOpen} className="absolute top-0 left-0">
        <AlertDialog.Portal>
          <AlertDialog.Overlay
            onClick={() => setIsOpen(false)}
            className="fixed bg-black bg-opacity-30 w-screen h-screen z-50 top-0 left-0 flex"
          />
          {/* <AlertDialog.Content className="bg-white fixed z-50 mx-auto inset-x-0 top-1/2 -mt-20 max-w-lg rounded-lg p-4 shadow-lg"> */}
          <AlertDialog.Content className="bg-white fixed z-50 mx-auto inset-x-0 bottom-0 sm:top-1/2 sm:bottom-auto sm:-mt-20 sm:max-w-lg rounded-lg p-4 shadow-lg">
            <AlertDialog.Title className=" font-medium text-lg mb-4">
              Vérification du numéro de TVA.
            </AlertDialog.Title>

            <AlertDialog.Description className=" text-base text-gray-500 font-light mb-8">
              <ScrollArea.Root className="w-full h-[20vh] max-h-[20vh] rounded overflow-hidden  bg-white">
                <ScrollArea.Viewport
                  className="w-full h-full rounded relative"
                  ref={scrollableDivRef}
                >
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Ratione quidem perferendis, sequi recusandae eveniet delectus
                  non quam iure ullam deserunt unde alias neque molestiae,
                  cupiditate dicta totam. Eaque suscipit, libero sed dicta,
                  nihil unde placeat ea reiciendis saepe exercitationem
                  inventore consectetur nam! Itaque voluptate numquam molestias
                  distinctio sit? Et incidunt ea fugiat nam eos ad? Quaerat
                  illum voluptatibus, amet, adipisci sed saepe necessitatibus
                  ullam ducimus debitis placeat itaque labore quod culpa
                  quisquam. Ad vel voluptatem amet sequi explicabo dolor
                  temporibus enim consequuntur corrupti laboriosam praesentium
                  eligendi, aliquam alias, cumque quisquam, quo modi omnis
                  dignissimos. Officia molestias nisi quisquam quod rerum nihil
                  incidunt illum optio iusto in exercitationem ratione
                  excepturi, assumenda id commodi distinctio animi ad ipsa
                  aspernatur corporis eligendi perspiciatis? Maiores facere
                  voluptates repellendus, autem repudiandae, voluptatem corporis
                  adipisci accusamus qui esse deleniti hic blanditiis! Quibusdam
                  neque sit magni eligendi saepe exercitationem quod. Quis,
                  vitae temporibus enim, quidem explicabo molestias corporis
                  pariatur nulla rerum, expedita quas at vero aspernatur magnam
                  asperiores. Molestiae, non? Culpa eum, ducimus mollitia
                  dolores velit repudiandae iusto similique. Neque at, dicta
                  aperiam consequuntur, nobis necessitatibus atque quo totam
                  quae rem sapiente impedit perferendis ipsum tenetur possimus
                  magnam quasi eveniet esse doloremque sit. Eveniet voluptatem
                  ratione iure.
                  <div className="sticky w-full z-50 h-6 bg-gradient-to-b from-transparent to-white bottom-0">
                    <button
                      onClick={handleScroll}
                      disabled={!isButtonVisible}
                      className={`text-xs justify-center bg-white text-center p-2 border rounded-full cursor-pointer absolute ${
                        isButtonVisible ? "opacity-100" : "opacity-0"
                      } right-0 -top-4 transition-all ease-out`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                        />
                      </svg>
                    </button>
                  </div>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                  className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                  orientation="vertical"
                >
                  <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                  className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                  orientation="horizontal"
                >
                  <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner className="bg-blackA5" />
              </ScrollArea.Root>
            </AlertDialog.Description>
            <div className="flex justify-end gap-x-2">
              <AlertDialog.Cancel>
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className="bg-gray-200 py-2 px-4 text-gray-600 rounded font-medium"
                >
                  En attente
                </button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <button className="bg-trinidad-100 py-2 px-4 text-trinidad-600 rounded font-medium">
                  Continuer
                </button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};

export default TestModalPage;
