import React from "react";
import Layout from "../../components/layout/Layout";

const JobDevPage = () => {
  return (
    <Layout>
      <div className="job-page">
        <h1>ByVets recherche un.e Développeur. euse front-end</h1>
        <h2>Vous êtes sans doute la personne que nous cherchons ...</h2>
        <h3>Qui sommes-nous ?</h3>
        <p>
          ByVets est une société coopérative de vétérinaires, animés par le
          désir de mettre en commun leurs ressources et connaissances afin de
          gérer eux-même leur informatique. Plus de 300 cabinets et cliniques
          vétérinaires en Belgique francophone nous font confiance à ce jour et
          utilisent le logiciel de gestion médico-commerciale développé par
          l’association, tant sur plateforme MacOS que Windows. Nous prenons
          plaisir à travailler et à offrir à nos utilisateurs un projet de
          qualité, dans un climat d'entraide confraternelle et une ambiance
          conviviale.
        </p>
        <p className="block text-center my-4 font-semibold italic">
          Dans le cadre de notre croissance, venez partager votre enthousiasme
          et révéler votre talent.
        </p>
        <h3>Description du poste</h3>
        <p>Au sein de notre équipe passionnée,</p>
        <ul>
          <li>
            Vous participerez au développement de nouvelles fonctionnalités
            ainsi qu'au perfectionnement et à la maintenance du logiciel
            existant
          </li>
          <li>
            Vous réaliserez ces développements avec des langages orientés objets
            et multi- plateforme
          </li>
          <li>
            Vous pourriez également prendre une part active dans les
            développements de l'application sur plateforme web (cloud), ...
          </li>
          <li>
            Vous pourriez intervenir, à distance, dans l’installation et
            l’assistance à l'utilisation du logiciel Pégase par les cabinets
            vétérinaires.
          </li>
        </ul>
        <h3>Profil</h3>
        <p>
          Formation supérieure en informatique et/ou expérience dans le
          développement logiciel.
        </p>
        <ul>
          <li>
            Vous avez une bonne connaissance du développement dans un
            environnement mixte MacOS-Windows, de même qu'en environnement
            client-serveur (réseau). Une connaissance d’iOS et Androïd est
            bienvenue.
          </li>
          <li>
            Vous êtes motivé.es par la programmation orientée objets, la
            programmation web et les technologies bases de données
            relationnelles SQL.
          </li>
        </ul>
        <p>Vous possédez une bonne maitrise de l'anglais (B2).</p>
        <p>Vous parlez néerlandais ? C’est encore mieux.</p>
        <p>Vos connaissances en graphisme seront bienvenues.</p>
        <h3>Offre</h3>
        <p>
          Contrat : Temps plein à durée indéterminée (CDI), une grande partie
          des prestations pourra s'effectuer en télétravail avec une grande
          flexibilité horaire.
        </p>
        <p>
          Cadre de travail : Convivial et décontracté. Nous sommes un société à
          taille humaine dans laquelle vous pourrez vous impliquer à chaque
          étape du développement.
        </p>
        <p>
          Salaire : Un salaire attractif et compétitif, des chèques repas , des
          avantages en nature : pack de téléphonie portable (GSM+abonnement), PC
          portable, internet à domicile.
        </p>
        <div className="text-center my-8">
          <a
            className="text-sm sm:text-base flex items-center justify-center content-button bg-trinidad-500 text-white font-light rounded-full text-center py-2 px-10 sm:p-2 cursor-pointer"
            href="mailto:francois.vanlerberghe@byvets.be"
          >
            Postuler
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default JobDevPage;
